import { AsYouType } from 'libphonenumber-js'

const title = {
  format: value => {
    return value
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  },
}

const lower = {
  format: value => {
    return value.toLowerCase()
  },
}

const upper = {
  format: value => {
    return value.toUpperCase()
  },
}

const phone = {
  format: value => {
    const parser = new AsYouType('AR')
    parser.input(value)
    return parser.getNumber()
      ? parser.getNumber().number
      : null
  },
}

export default {
  lower,
  upper,
  title,
  phone,
}
